<template>
  <div>
    <a-modal
      title="School Year Confirmation"
      :visible="visibleModal"
      :confirm-loading="confirmLoadingModal"
      @ok="handleOkStatusTahunAjaran"
      @cancel="handleCancelStatusTahunAjaran"
    >
      <p>Are you sure want to change the school year ? your change will be affected to all users</p>
    </a-modal>
    <a-row :gutter="16">
      <a-col :xs="24" :lg="8">
        <Card>
          <div class="cui__utils__heading mb-0">
            <strong>SCHOOL YEAR</strong>
          </div>
          <div class="text-muted mb-3">You can change school year here</div>
          <hr />
          <ListTahunAjar
            :listData="dataTahunAjaran"
            @change="changeTahunAjaran"
          />
        </Card>
      </a-col>
      <!-- <a-col :xs="24" :lg="16">
        <Card>
          <TemplateDescription />
        </Card>
      </a-col> -->
    </a-row>
  </div>
</template>

<script>
// import JitsiMeet from '@/components/app/JitsiMeet'
// import generateToken from '@/helpers/token.js'
import Card from '@/components/app/Card'
import ListTahunAjar from '@/components/app/ListTahunAjar'
// const TemplateDescription = () => import('@/components/app/Report/TemplateDescription')
export default {
  components: {
    Card,
    ListTahunAjar,
    // TemplateDescription,
  },
  data() {
    return {
      visibleModal: false,
      confirmLoadingModal: false,
      dataTahunAjaran: [],
      selectedTahunAjaran: '',
    }
  },
  mounted() {
    this.fetchTahunAjar()
  },
  computed: {
  },
  methods: {
    showModal() {
      this.visibleModal = true
    },
    handleOkStatusTahunAjaran(e) {
      this.confirmLoadingModal = true
      this.$store.dispatch('admin/UPDATE_STATUS_TAHUN_AJARAN', { id: this.selectedTahunAjaran })
        .then(res => {
          this.visibleModal = false
          this.confirmLoadingModal = false
          if (res === 'SUCCESS_UPDATE_STATUS_TAHUN_AJARAN') {
            this.$notification.success({
              message: 'Success.',
              description: 'Tahun Ajaran has been updated',
            })
            this.fetchTahunAjar()
          } else {
            this.$notification.error({
              message: 'Error.',
              description: 'Tahun Ajaran has not been updated',
            })
          }
        })
    },
    handleCancelStatusTahunAjaran(e) {
      this.visibleModal = false
    },
    changeTahunAjaran(payload) {
      this.selectedTahunAjaran = payload
      this.showModal()
    },
    fetchTahunAjar() {
      this.$store.dispatch('user/FETCH_TAHUN_AJARAN')
        .then(res => {
          this.dataTahunAjaran = res
        })
    },
    onIFrameLoad () {
      // $('iframe[name="jitsiConferenceFrame0"] document').ready(() => {
      // $('button[name="jqi_state0_buttonspandatai18ndialogIamHostIamthehostspan"]').trigger('click')
      // $('.aui-dialog2-content input[name="username"]').val('moderator')
      // $('.aui-dialog2-content input[name="password"]').val('moderator')
      // this.$refs.jitsiRef.addEventListener('participantRoleChanged', (event) => {
      // if (event.role === 'moderator') {
      //   this.$refs.jitsiRef.executeCommand('password', 'conference_password')
      // }
      // })
      // })
    },
  },
}
</script>

<style>
</style>
